import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  image?: ImageProps
  services: ServiceProps[]
  title?: string
}

export const ServicesSimple = memo(function ServicesSimple({
  image,
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container row dial={5} tag="section" wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>
      <RightSide dial={4}>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <Services row space="between" wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  padding-top: 10.4375rem;

  @media (max-width: 1199px) {
    padding-top: 6.25rem;
  }

  @media (max-width: 1023px) {
    padding-top: 0;
  }
`

const LeftSide = styled.div`
  width: 34.5%;
  position: relative;
  overflow: hidden;
  padding-bottom: 45%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const RightSide = styled(FlexBox)`
  width: 65.5%;
  padding: 0 6.667vw 5rem 9.028vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3.75rem 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.375rem;

  @media (max-width: 1023px) {
    font-size: 1.625rem;
    line-height: 2rem;
  }
`

const Services = styled(FlexBox)`
  margin-top: 0.9375rem;

  @media (max-width: 1023px) {
    margin-top: 0.5rem;
  }
`
