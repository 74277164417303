import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  label?: string
}

export const Service = memo(function Service({ label }: Props) {
  if (!label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {label}
    </Container>
  )
})

const Container = styled(motion.div)`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
  margin-top: 3rem;
  padding: 0 3.333vw 0 2rem;
  position: relative;
  &:before {
    content: '';
    width: 1.25rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    top: 0.9375rem;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem 0 2rem;
    margin-top: 2rem;
  }

  @media (max-width: 767px) {
    margin-top: 1.5rem;
  }

  @media (max-width: 574px) {
    margin-top: 0.75rem;
    width: 100%;
  }
`
